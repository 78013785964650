const api = "https://manoplace.com";

const serverUrl = api+"/api/consult";

const imageUrl = api+"/images";

const app_token = "bWFuby1vbmxpbmUtbWVudS0zMDA2MjAyMw==";

const site_id = 1;

export {
    serverUrl,
    site_id,
    app_token,
    imageUrl
}
