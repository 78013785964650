<template>
	<Header />
	<CContainer class="m-auto">
		<router-view></router-view>
	</CContainer>
	<Footer />
</template>

<script>
	import { CContainer } from "@coreui/vue";
	import Header from "./components/Header.vue";
	import Footer from "./components/Footer.vue";
	import Content from "./components/Content.vue";

	export default {
		name: "App",
		components: {
			Header,
			Footer,
			// Content,
		},
	};
</script>

<style>
	#main-box {
		position: fixed;
		height: 100%;
		width: 100%;
		z-index: -2000;
	}
</style>
