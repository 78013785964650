<template>
    <CHeader position="sticky" class="app-bg text-uppercase">
        <CContainer fluid class="m-auto">
            <CHeaderBrand v-if="site!=null" href="#" class="fs-5 fw-bold app-text">
                <img :src="site.logo_url" :alt="site.name" height="30"/>
                Menu {{ site.name }}
            </CHeaderBrand>
            <CHeaderNav>
                <CNavItem>
                    <CNavLink href="https://goo.gl/maps/1HfB6ZeMGYX8vAn56" 
                        target="_blank" class="app-text">
                        Nous localiser
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink href="https://wa.me/237655553337" target="_blank" class="app-text">
                        <CIcon :icon="cilPhone"  class="text-success">
                        </CIcon>&nbsp;6&nbsp;55&nbsp;55&nbsp;33&nbsp;37
                    </CNavLink>
                </CNavItem>
            </CHeaderNav>
        </CContainer>
    </CHeader>
</template>

<script>

import {serverUrl, site_id, app_token} from '../settings'
import axios from 'axios';
import CIcon from '@coreui/icons-vue';
import { cilPhone  } from '@coreui/icons';

export default {
    components: {
        CIcon
    },
    setup(){
        return {
            cilPhone
        }
    },
    data (){
        return {
            site: null
        }
    },
    methods: {
        loadData(){
            axios.get(
                serverUrl+'/sites/'+site_id+'?token='+app_token,
                {
                    Headers: {
                        'Accept': 'application/json'
                    }
                }
            ).then((resp)=>{
                this.site = resp.data.site
            }).catch((err)=>{
                console.log(err);
            })
        }
    },
    mounted (){
        this.loadData()
    }
}
</script>

<style scoped>
    .menu-title{
        color: bisque !important;
        font-family:'Courier New', Courier, monospace !important;
        text-transform: uppercase;
    }

    #menu-title{
        font-size: 60px;
    }

    #small-menu-title{
        font-size: 25px;
    }
</style>