import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@coreui/coreui/dist/css/coreui.min.css'
import '@coreui/coreui/dist/js/coreui.min.js'
import './assets/styles.css'

import {
    CContainer,
    CNavbar,
    CHeader,
    CFooter,
    CHeaderBrand,
    CHeaderNav,
    CNavItem,
    CNavLink,
    CLink,
    CRow,
    CCard,
    CCardBody,
    CCardImage,
    CCardImageOverlay,
    CCardTitle,
    CCardText,
    CButton,
    CBreadcrumb,
    CBreadcrumbItem
} from '@coreui/vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createApp } from 'vue'
import App from './App.vue'
import Content from './components/Content.vue'

const routes = [
    {path : '/', component: Content},
    {path : '/:slug', component: Content}
]

const router = createRouter({
    routes: routes,
    history: createWebHistory()
});

const app = createApp(App)

app.use(router)

app.component('CContainer', CContainer)
app.component('CNavbar', CNavbar)
app.component('CHeader', CHeader)
app.component('CHeaderBrand', CHeaderBrand)
app.component('CHeaderNav', CHeaderNav)
app.component('CNavItem', CNavItem)
app.component('CNavLink', CNavLink)
app.component('CRow', CRow)
app.component('CLink', CLink)
app.component('CFooter', CFooter)
app.component('CCard', CCard)
app.component('CCardImage', CCardImage)
app.component('CCardText', CCardText)
app.component('CCardBody', CCardBody)
app.component('CButton', CButton)
app.component('CCardTitle', CCardTitle)
app.component('CBreadcrumb', CBreadcrumb)
app.component('CBreadcrumbItem', CBreadcrumbItem)

app.mount('#app')
