<template>
    <footer class="app-bg text-center text-lg-start mt-2">
        <div class="text-center">
            <a href="#" class="app-text">
                Copyright 2023 &copy; Digimarket 
            </a>
        </div>
    </footer>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style scoped>
    .resto-theme {
		background-color: orange !important;
        color: #ece9dd;
        font-size: x-small;
	}
    .resto-theme a {
        color: aliceblue;
    }
</style>