<template>
    <CCol xs>
        <CCard class="h-100 shadow bg-body rounded">
            <CCardImage 
                v-if="menu_type.image==null" 
                orientation="top" 
                src="/menu.png" />
            <CCardImage v-else 
                orientation="top" 
                :src="imageUrl +'/'+ menu_type.image" 
                />
            <CCardBody class="p-1">
                <CCardTitle>
                    <span class="d-block d-sm-none" style="font-size: 8.5pt !important;">{{ menu_type.name }}</span>
                    <span class="d-none d-sm-block d-md-none fs-6">{{ menu_type.name }}</span>
                    <span class="d-none d-md-block fs-5">{{ menu_type.name }}</span>
                </CCardTitle>
                <!-- <CCardText>{{ menu_type.description }}</CCardText> -->
                <a :href="make_url(menu_type)" class="btn w-100">
                    <span class="d-block d-sm-none" style="font-size: 7.8pt !important;">Afficher</span>
                    <span class="d-none d-sm-block d-md-none fs-6">Afficher</span>
                    <span class="d-none d-md-block fs-5">Afficher</span>
                </a>
            </CCardBody>
        </CCard>
    </CCol>
</template>


<script>

import { imageUrl } from '../../settings.js'

export default {
    props: ['menu_type'],
    components: {},
    data (){
        return {
            imageUrl: imageUrl,
        }
    },
    methods: {
        loadItems(){
            this.$emit('selected', this.menu_type);
        },
        make_url(menu_type){
            let slug = menu_type.name.replace(' ', '-').toLowerCase();
            return '/'+slug+'_'+menu_type.id;
        }
    }
}
</script>

<style scoped>
    .menu-type {
        font-size: 45px;
        z-index: 50000;
        color: black !important;
    }
</style>