<template>
    <CCol xs>
        <CCard class="h-100 shadow bg-body rounded">
            <CCardImage 
                v-if="item.image==null&&item.item_type_id==1" 
                orientation="top" 
                src="/food.png" />
            <CCardImage 
                v-else-if="item.image==null&&item.item_type_id==2" 
                orientation="top" 
                src="/drink.png" />
            <CCardImage 
                v-else-if="item.image==null&&item.item_type_id==3" 
                orientation="top" 
                src="/drink.png" />
            <CCardImage v-else 
                orientation="top" 
                :src="imageUrl +'/'+ item.image" 
                />
            <CCardBody class="p-1">
                <CCardTitle class="">
                    <span class="d-block d-sm-none" style="font-size: 8.5pt !important;">{{ item.name }}</span>
                    <span class="d-none d-sm-block d-md-none fs-6">{{ item.name }}</span>
                    <span class="d-none d-md-block fs-5">{{ item.name }}</span>
                </CCardTitle>
                <!-- <CCardText>{{ item.description }}</CCardText> -->
                <CText class="text-center fw-bold w-100 sticky-bottom app-price">
                    <span class="d-block d-sm-none" style="font-size: 8.5pt !important;">{{ item.price }} F CFA</span>
                    <span class="d-none d-sm-block d-md-none fs-6">{{ item.price }} F CFA</span>
                    <span class="d-none d-md-block fs-5">{{ item.price }} F CFA</span>
                </CText>
            </CCardBody>
        </CCard>
    </CCol>
</template>

<script>
import {imageUrl} from '../../settings'
export default {
    props: ['item'],
    data (){
        return {
            imageUrl: imageUrl
        }
    }
}
</script>

<style lang="scss" scoped>

</style>