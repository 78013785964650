<template>
    <CBreadcrumb>
        <CBreadcrumbItem href="/"
            :active="menu_type==null"
            class="h1 app-text app-url"
            v-on:click="resetDisplay">
            <router-link to="/">
                MENU
            </router-link>
        </CBreadcrumbItem>
        <CBreadcrumbItem 
            v-if="menu_type!=null" 
            class="h1 app-text app-url"
            :active="menu_type!=null">
            {{ menu_type.name }}
        </CBreadcrumbItem>
    </CBreadcrumb>
    <CRow 
        v-if="menu_type==null" 
        :xs="{ cols: 3, gutter: 4}" 
        :md="{ cols:4 }" 
        :lg="{ cols:6 }">
        <Type 
            v-for="menu_type in menu_types" 
            :key="menu_type.id" 
            :menu_type="menu_type"
            @selected="handleTypeChange"/>
    </CRow>
    <CRow 
        v-if="menu_type!=null" 
        :xs="{ cols: 3, gutter: 4}" 
        :md="{ cols:4 }" 
        :lg="{ cols:6 }">
        <Item 
            v-for="item in items" 
            :key="item.id" 
            :item="item"/>
    </CRow>
</template>

<script>

import {serverUrl, site_id, app_token} from '../settings'
import axios from 'axios';
import {useRoute} from 'vue-router'
import { watch } from 'vue';

import Type from './sections/Type.vue'
import Item from './sections/Item.vue'
export default {
    components: {
        Type,
        Item
    },  
    data (){
        return {
            menu_types: [],
            groups: [],
            items: [],
            menu_type: null,
        }
    },
    methods: {
        loadData(){
            axios.get(
                serverUrl+'/types?site_id='+site_id+'&token='+app_token,
                {
                    Headers: {
                        'Accept': 'application/json'
                    }
                }
            ).then((resp)=>{
                this.menu_types = resp.data
                this.handleRouteStatus()
            }).catch((err)=>{
                console.log(err);
            })
        },
        loadTypeGroups(type_id){
            axios.get(
                serverUrl+'/groups?site_id='+site_id+'&token='+app_token+'&menu_type_id='+type_id,
                {
                    Headers: {
                        'Accept': 'application/json'
                    }
                }
            ).then((resp)=>{
                this.groups = resp.data
            }).catch((err)=>{
                console.log(err);
            })
        },
        loadTypeItems(type_id){
            let url = serverUrl+'/items?site_id'+site_id
                url += '&menu_type_id='+type_id
                url += '&token='+app_token
            axios.get(
                url,
                {
                    Headers: {
                        'Accept': 'application/json'
                    }
                }
            ).then((resp)=>{
                this.items = resp.data
            }).catch((err)=>{
                console.log(err);
            })
        },
        handleTypeChange(value){
            this.menu_type = value
            this.loadTypeItems(this.menu_type.id);
        },
        resetDisplay(){
            this.menu_type = null;
            this.groups = null;
            this.items = null;
            this.$router.go('/')
        },
        handleRouteStatus(){
            let path = this.$route.path
            if( path !== '/'){
                let menu_type_id = path.split('_')[1]
                let menu_type = this.menu_types.find((item) => item.id==menu_type_id)
                this.handleTypeChange(menu_type);
            }
        }
    },
    mounted (){
        this.loadData();
    }
}
</script>

<style scoped>

</style>